import { useEffect, useState } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useTranslation } from "next-i18next";
import { like_dislike_saga } from "../../store/actions/posts";

const commentsHook = (props) => {
    const [like_dislike, setLikeOrDislike] = useState(null);
    const [isClick, setClick] = useState(false);
    const [activeInput, setActive] = useState(false);
    const [seeMore, setSeeMore] = useState(false);
    const [expanded, setExpand] = useState(false);
    const [isClinet, setIsClinet] = useState(false);
    const [likeCount, setLikeCount]=useState(0);
    const settings = useSelector(state => state.settings);
    const [report_text, setReportText] = useState("");
    const comments = useSelector(state => state.comments);
    const translate = useSelector(state => state.translate);
    const router = useRouter();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const posts_like_dislike = (like_count) => {        
        dispatch(
            like_dislike_saga({ id: props.id, token: props.token, cb:()=>{
                setLikeOrDislike(true);
                setClick(true)
                setLikeCount(likeCount?+likeCount+1:+like_count+1)
            } }),
            
        );
    };
    
    useEffect(() => {
        if (isClick) {
            const timeoutId = setTimeout(() => {
                setClick(false)
            }, 1000);
            return () => clearTimeout(timeoutId);
        }
    }, [isClick]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [open_report, setOpenReport] = useState(false);
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);

        // props.report_post();
    };
    function rand() {
        return Math.round(Math.random() * 20) - 10;
    }

    function getModalStyle() {
        const top = 50 + rand();
        const left = 50 + rand();

        return {
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
        };
    }

    const useStyles = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '10px',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }));
    const classes = useStyles();
    switch (i18n.language) {
        case "ru":
            moment.locale('ru');
            break;
        case "en":
            moment.locale('eng');
            break;
        case "fr":
            moment.locale('fr');
            break;
        default:
            break;
    }
    useEffect(() => {
        setIsClinet(true);
        
    }, []);
    return {
        anchorEl,
        open,
        open_report,
        classes,
        modalStyle,
        report_text,
        translate,
        comments,
        like_dislike,
        likeCount,
        activeInput,
        expanded,
        seeMore,
        settings,
        moment,
        isClinet,
        router,
        isClick,
        // goToRegisterMaster,
        dispatch,
        rand,
        setSeeMore,
        posts_like_dislike,
        setExpand,
        setAnchorEl,
        setActive,
        setOpen,
        setOpenReport,
        handleClick,
        handleClose,
        setReportText,
        getModalStyle,
        useStyles,

    };
};
export default commentsHook;