import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
function PostCommentSlider(props) {
    const {t}= useTranslation();
    const settings = {
        dots: false,
        // infinite: false,
        className: "center-mode",
        centerMode: true,
        infinite: true,
        speed: 500,
        arrows: false,
        // height: 115,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
       
    };
    return (
        <div className='slider-container'>
            {props?.order_count>0 && <div className='order-count'>{t('order')} {props?.order_count}</div>}
            <Slider {...settings}>
                {props?.array?.map((item, index) => {
                    return <div
                        key={index}
                        className='item'>
                        <span>{item?.comment}</span>
                    </div>
                })}
            </Slider>
        </div>
    );
}
export default PostCommentSlider;